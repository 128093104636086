<template>
  <!-- 显隐 -->
  <div v-if="showDialog">
    <!-- 遮罩层 -->
    <transition name="fade" appear>
      <div class="modal-overlay"></div>
    </transition>
    <!-- 弹窗 -->
    <transition name="pop" appear>
      <div class="modal" role="dialog" :style="{ width: `${width}px` }">
        <!-- 头部 -->
        <div class="model-title">
          <div class="title-text">
            <span>{{ title }}</span>
            <div class="icon-close" v-if="showClose">
              <i class="el-icon-close" @click="close"></i>
            </div>
          </div>
          <!-- <div class="semiel-lipse"></div> -->
        </div>
        <!-- 内容 -->
        <div class="model-body">
          <div class="hint_Name">{{ hintName }}</div>
          <slot></slot>
        </div>
        <!-- 底部 -->
        <div class="footer_btn">
          <div
            class="dis_in_center left-btn"
            :class="{ margin_left_20: showCancelButton }"
            v-if="showCancelButton"
          >
            <van-button :size="size" @click="close">{{
              cancelText
            }}</van-button>
          </div>
          <div class="dis_in_center right-btn" v-if="showConfimButton">
            <van-button
              :disabled="disabled"
              :loading="confimBtnLoading"
              :size="size"
              @click="confim"
            >
              {{ confimText }}
            </van-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
  <script>
export default {
  name: "CustomDialog",
  components: {},
  model: {
    prop: "showDialog",
    event: "change",
  },
  props: {
    // 弹框的显示隐藏
    showDialog: {
      type: Boolean,
      default: false,
    },
    // 底部按钮大小
    size: {
      type: String,
      default: "normal",
    },
    // 宽度设置
    width: {
      type: [String, Number],
      default: "600",
    },
    // 是否显示右上角的关闭按钮
    showClose: {
      type: Boolean,
      default: true,
    },
    // 弹框标题
    title: {
      type: String,
      default: "标题",
    },
    // 确认按钮
    confimText: {
      type: String,
      default: "确认",
    },
    // 确认按钮loading
    confimBtnLoading: {
      type: Boolean,
      default: false,
    },
    // 是否显示确认按钮
    showConfimButton: {
      type: Boolean,
      default: true,
    },
    // 是否显示取消按钮
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    // 是否禁用确认按钮
    disabled: {
      type: Boolean,
      default: false,
    },
    // 确认按钮类型
    confimBtnType: {
      type: String,
      default: "primary",
    },
    // 取消按钮类型
    cancelType: {
      type: String,
      default: "info",
    },
    // 取消按钮文本
    cancelText: {
      type: String,
      default: "取消",
    },
    // 取消按钮文本
    hintName: {
      type: String,
      default: "",
    },
    // 关闭按钮前的事件处理
    beforeClose: {
      type: Function,
      default: () => {
        return true;
      },
    },
  },
  watch: {
    showDialog(news, olds) {
      this.stopBodyScroll(news);
    },
  },
  methods: {
    close() {
      const data = this.beforeClose();
      if (!data) return;
      this.$emit("change", false);
    },
    confim() {
      this.$emit("confim", false);
    },
    stopBodyScroll(isFixed) {
      let bodyEl = document.body;
      let top = 0;
      if (isFixed) {
        top = window.scrollY;
        bodyEl.style.width = "100%";
        bodyEl.style.position = "fixed";
        bodyEl.style.top = -top + "px";
      } else {
        bodyEl.style.position = "";
        bodyEl.style.top = "";
        window.scrollTo(0, top); // 回到原先的top
      }
    },
  },
};
</script>
</style>
  <style lang="less"  scoped>
.modal {
  position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: fit-content;
  box-shadow: 0px 5px 15px 0px rgba(12, 30, 233, 0.3);
  min-width: 220px;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  z-index: 999;
  transform: none;
}

.model-body {
  //   padding: 2rem;
  //   min-height: 2rem;
  //   max-height: 50vh;
  overflow-y: auto;
  .hint_Name {
    font-size: 15px;
    color: #666666;
  }
}

.model-title {
  position: relative;
  //   height: 64px;
  text-align: center;
  overflow: hidden;
  //   line-height: 64px;
  width: 100%;
  color: #000;
  font-size: 18px;
  margin: 20px 0 10px 0;
}

.semiel-lipse {
  position: absolute;
  width: 110%;
  left: -5%;
  height: 150%;
  top: -50%;
  background: rgba(9, 24, 236, 0.8);
  border-radius: 8px;
}

.title-text {
  position: relative;
  z-index: 666;
}

.icon-close {
  position: absolute;
  right: 42px;
  top: 0;
  line-height: 44px;
}

.icon-close i {
  cursor: pointer;
}

.modal-overlay {
  content: "";
  position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 888;
  background: rgba(0, 0, 0, 0.4);
}

.footer_btn {
  text-align: center;
  padding-bottom: 20px;
  padding-top: 20px;
  //   border-top: 1px solid #f5f5f5;
}

.dis_in_center {
  display: inline-block;
  vertical-align: middle;
  //   border: 1px solid #000;
  //   border-radius: 10px;
  font-size: 18px;
  //   padding: 10px;
}

/* ---------------------------------- */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.pop-enter-active,
.pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}
.left-btn {
  .van-button {
    border-radius: 5px;
    color: #9fa0a0;
    background-color: #edeff3;
    width: 110px;
    height: 40px;
  }
}
.right-btn {
  margin-left: 20px;
  .van-button {
    border-radius: 5px;
    color: #fff;
    background-color: #558bff;
    width: 110px;
    height: 40px;
  }
}
</style>
  