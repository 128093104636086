var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Four_boxgrid"},[_c('div',{class:[
      'cardBox',
      _vm.gonggeshow == 9 ? 'SudokuBox' : '',
      _vm.gonggeshow == 16 ? 'SixteenHousesBox' : '',
    ]},[_vm._l((_vm.InstanceList),function(item,index){return _c('div',{key:index,class:[
        'div_img',
        _vm.gonggeshow == 9 ? 'Sudoku' : '',
        _vm.gonggeshow == 16 ? 'SixteenHouses' : '',
      ]},[_c('div',{staticClass:"ysj_name"},[_c('div',{staticClass:"ysj_head_left"},[_c('div',{staticClass:"CloudIconBox"},[(
                (item.vipCode == 'VIP1') & (item.deviceVersionCode == 'V8')
              )?_c('img',{attrs:{"src":require("../../assets/icons/biaozhunanzhuo8.png"),"alt":""}}):_vm._e(),(
                (item.vipCode == 'VIP1') & (item.deviceVersionCode == 'V10')
              )?_c('img',{attrs:{"src":require("../../assets/icons/biaozhunanzhuo10.png"),"alt":""}}):_vm._e(),(
                (item.vipCode == 'VIP2') & (item.deviceVersionCode == 'V8')
              )?_c('img',{attrs:{"src":require("../../assets/icons/changwananzhuo8.png"),"alt":""}}):_vm._e(),(
                (item.vipCode == 'VIP2') & (item.deviceVersionCode == 'V10')
              )?_c('img',{attrs:{"src":require("../../assets/icons/changwananzhuo10.png"),"alt":""}}):_vm._e(),(
                (item.vipCode == 'VIP3') & (item.deviceVersionCode == 'V8')
              )?_c('img',{attrs:{"src":require("../../assets/icons/aorananzhuo8.png"),"alt":""}}):_vm._e(),(
                (item.vipCode == 'VIP3') & (item.deviceVersionCode == 'V10')
              )?_c('img',{attrs:{"src":require("../../assets/icons/aorananzhuo10.png"),"alt":""}}):_vm._e(),(
                (item.vipCode == 'VIP4') & (item.deviceVersionCode == 'V8')
              )?_c('img',{attrs:{"src":require("../../assets/icons/zhizunanzhuo8.png"),"alt":""}}):_vm._e(),(
                (item.vipCode == 'VIP4') & (item.deviceVersionCode == 'V10')
              )?_c('img',{attrs:{"src":require("../../assets/icons/zhizunanzhuo10.png"),"alt":""}}):_vm._e(),(
                (item.vipCode != 'VIP1') &
                (item.vipCode != 'VIP2') &
                (item.vipCode != 'VIP3') &
                (item.vipCode != 'VIP4')
              )?_c('img',{attrs:{"src":require("../../assets/icons/dingzhianzhuo.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"nameItem"},[_c('div',{staticClass:"instanceName"},[_c('div',{staticClass:"typeName"},[_vm._v(_vm._s(item.instanceName))]),(item.isAuthorized == 2)?_c('div',{staticClass:"yishouquan"},[_vm._v(" 已授权 ")]):_vm._e(),(item.isAuthorized == 1)?_c('div',{staticClass:"beishouquan"},[_vm._v(" 被授权 ")]):_vm._e()]),_c('van-count-down',{staticClass:"instanceItem",attrs:{"time":item.countDown,"format":item.countDown > 86400000
                  ? '剩余:DD天HH小时mm分钟'
                  : '剩余:HH小时mm分钟ss秒'}})],1)]),(item.isAuthorized != 1)?_c('div',{staticClass:"renewRight",on:{"click":function($event){return _vm.xushi(item)}}},[_vm._v(" 续费 ")]):_vm._e()]),(item.maintenanceStatus == 1)?_c('div',{staticClass:"weihu"},[_vm._m(0,true),_c('div',[_c('p',[_vm._v("本云手机正在维护中预计维")]),_c('p',[_vm._v("护时间为：")]),_vm._v(" "+_vm._s(item.maintenanceText)+" "),_c('p',[_vm._v("维护时间结束后请点击刷新按钮")]),_c('p',[_vm._v("建议维护完成 后重启云手机")]),_c('p',[_vm._v("给您带来不便敬请谅解")])])]):(item.overSoldDevice)?_c('div',{staticClass:"weihu",on:{"click":function($event){return _vm.replace(item)}}},[_vm._m(1,true),_vm._m(2,true),_c('div',{staticClass:"weihuButton"},[_vm._v("立即更换")])]):_c('div',{staticClass:"feiweihu",attrs:{"data_ref":item.instanceCode},on:{"click":function($event){return _vm.BgsSdk_child(item, index)}}},[(_vm.shotinfoList && _vm.shotinfoList.length != 0 && _vm.imageurl(item))?_c('img',{staticClass:"imgs",attrs:{"alt":"","src":_vm.imageurl(item)}}):_c('img',{staticClass:"imgs",attrs:{"src":require("../../assets/img/homeAmend/loadLine.png"),"alt":""}})]),(item.remind < 3 && item.statusShow && item.isAuthorized != 1)?_c('div',{staticClass:"remind"},[_c('img',{staticClass:"x_remind",attrs:{"src":require("../../assets/img/homeAmend/remind_x.png"),"alt":""},on:{"click":function($event){return _vm.bad(index, item)}}}),_c('div',{class:_vm.atPresent_shake(item.instanceCode) != -1 ? 'shake-text' : ''},[_c('div',{staticClass:"title_remind"},[_vm._v("到期提醒")]),_c('div',{staticClass:"text_remind",on:{"click":function($event){return _vm.pageRemind(item)}}},[_vm._v(" 云手机时长"),_c('span',[_vm._v("不足"+_vm._s(item.remind + 1)+"天")]),_vm._v("，到期将清空数据"),_c('br'),_c('span',[_vm._v("送您一个续费优惠")]),_vm._v("，点击立即领取使用 ")])])]):_vm._e(),(
          item.remind >= 3 &&
          item.remind < 7 &&
          item.statusShow &&
          item.isAuthorized != 1
        )?_c('div',{staticClass:"sevenExpiration"},[_c('img',{staticClass:"FrameName",attrs:{"src":require("../../assets/img/homeAmend/Frame.png"),"alt":""}}),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.pageRemind(item)}}},[_vm._v(" 云手机时长不足"+_vm._s(item.remind + 1)+"天，到期将清空数据，请及时"),_c('span',[_vm._v("续费")])]),_c('img',{staticClass:"sevenX",attrs:{"src":require("../../assets/img/homeAmend/sevenX.png"),"alt":""},on:{"click":function($event){return _vm.bad(index, item)}}})]):_vm._e(),_c('img',{staticClass:"seting",attrs:{"src":require("../../assets/icons/settingm_g.png"),"alt":""},on:{"click":function($event){return _vm.popupshow(item)}}})])}),(_vm.getShow)?_c('div',{class:[
        'div_img',
        'lijigoumai',
        _vm.gonggeshow == 9 ? 'Sudoku' : '',
        _vm.gonggeshow == 16 ? 'SixteenHouses' : '',
      ],on:{"click":_vm.lijigoumai}},[_c('img',{staticClass:"addImg1",attrs:{"src":require("../../assets/img/homeAmend/addCloudPhone.png"),"alt":""}}),_c('img',{staticClass:"addImg2",attrs:{"src":require("../../assets/img/homeAmend/addText.png"),"alt":""}})]):_vm._e()],2),_c('Aochenpeizhi',{ref:"aochen_peizhis",attrs:{"Aochen_phone":_vm.aochenphone}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"weihuImg",attrs:{"src":require("../../assets/img/homeAmend/maintainImg.png"),"alt":""}}),_c('p',{staticClass:"weihuTitle"},[_vm._v("正在维护中...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"weihuImg",attrs:{"src":require("../../assets/img/homeAmend/malfunction.png"),"alt":""}}),_c('p',{staticClass:"weihuTitle",staticStyle:{"color":"red"}},[_vm._v("云手机故障...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("点击自动更换一台")])])
}]

export { render, staticRenderFns }