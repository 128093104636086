import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const server = [
    {
        path: '/channelPreloaded',
        name: 'ChannelPreloaded',
        component: () => import('../../views/channelPreloaded')
    },
    {
        path: '/moreData',
        name: 'MoreData',
        component: () => import('../../views/channelPreloaded/moreData')
    },
    {
        path: '/totalData',
        name: 'TotalData',
        component: () => import('../../views/channelPreloaded/totalData')
    },
]

export default server
