import router from "@/router";


export default {
    // 倒计时函数，作废
    countTime(endTime) {
        //获取当前时间
        var date = new Date();
        var now = date.getTime();
        //设置截止时间
        var endDate = new Date(endTime);
        var end = endDate.getTime();
        //时间差
        var leftTime = end - now;
        //定义变量 d,h,m,s保存倒计时的时间
        let d = ''
        let h = ''
        let m = ''
        let s = ''
        if (leftTime >= 0) {
            d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
            h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
            m = Math.floor((leftTime / 1000 / 60) % 60);
            s = Math.floor((leftTime / 1000) % 60);
        }
        setTimeout(this.countTime, 1000);
        return `${d}天${h}小时${m}分钟`
        //递归每秒调用countTime方法，显示动态时间效果

    },
    isAppleWebKit() {
        var userAgent = navigator.userAgent.toLowerCase();
        return userAgent.indexOf('applewebkit') !== -1 &&
            userAgent.indexOf('chrome') === -1 &&
            userAgent.indexOf('chromium') === -1 &&
            userAgent.indexOf('crios') === -1 &&
            userAgent.indexOf('fxios') === -1 &&
            userAgent.indexOf('safari') === -1;
    },

    // 当前时间转换为字符串格式日期
    getCurrentTime() {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    // 字符串日期转换为日期对象
    convertToDateObject(dateString) {
        // 解析日期字符串，确保月份和分钟不会被误解为8进制
        var dateParts = dateString.split(/[- :]/);
        // 获取年、月、日、小时、分钟、秒
        var year = dateParts[0];
        var month = dateParts[1] - 1; // 月份是从0开始的，所以要减1
        var day = dateParts[2];
        var hour = dateParts[3];
        var minute = dateParts[4];
        var second = dateParts[5];

        // 使用UTC时间创建新的日期对象
        return new Date(Date.UTC(year, month, day, hour, minute, second));
    },
    // 复制方法
    copy(value) {
        var aux = document.createElement("input");
        aux.setAttribute("value", value);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
        Toast("复制成功！");
      },
}
export function limitFloat(val){
    let sNum = val.toString(); //先转换成字符串类型
    if (sNum.indexOf('.') == 0) {//第一位就是 .
      console.log('first str is .')
      sNum = '0' + sNum
    }
    sNum = sNum.replace(/[^\d.]/g,"");  //清除“数字”和“.”以外的字符
    sNum = sNum.replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的
    sNum = sNum.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
    sNum = sNum.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');//只能输入两个小数
    //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    if(sNum.indexOf(".")< 0 && sNum !=""){
      sNum = parseFloat(sNum);
    }
    return sNum
  }

